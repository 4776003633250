import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(private router: Router) {
    document.getElementById('color-opt').setAttribute('href', 'assets/css/colors/purple.css');
  }

  ngOnInit() {

    document.body.classList.remove('bg-account-pages');
    document.body.classList.remove('py-4');
    document.body.classList.remove('py-sm-0');

    document.getElementById('theme_id').classList.remove('theme-purple');
    if (this.router.url == '/') {
      document.getElementById('theme_id').classList.add('theme-purple');
    }
  }
}
