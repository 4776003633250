<section class="section bg-light" id="services">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <h1 class="section-title text-center">Nos Services</h1>
                <div class="section-title-border mt-3"></div>
                <p class="section-subtitle text-muted text-center pt-4 font-secondary">
                    Nous mettons à la disposition de nos clients un large éventail de services adaptés grâce à nos compétences et expérience
                    diversifiées
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-4 mt-4">
                <div class="services-box">
                    <div class="d-flex">
                        <i class="pe-7s-notebook text-primary"></i>
                        <div class="ms-4">
                            <h4>Tenue de livres</h4>
                            <p class="pt-2 text-muted">Tenue, surveillance, établissement ou révision des comptes selon les normes canadiennes.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mt-4">
                <div class="services-box">
                    <div class="d-flex">
                        <i class="pe-7s-albums text-primary"></i>
                        <div class="ms-4">
                            <h4>Mission de compilation</h4>
                            <p class="pt-2 text-muted">
                                Préparation des états financiers annuels et intérimaire. Nous vous fournissons le rapport
                                d'état financier dans un format approprié pour une meilleure compréhension de
                                l'information financière.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mt-4">
                <div class="services-box">
                    <div class="d-flex">
                        <i class="pe-7s-note2 text-primary"></i>
                        <div class="ms-4">
                            <h4>Déclarations de revenus de particulier (T1/TP1)</h4>
                            <p class="pt-2 text-muted">Nous vous produisons des déclarations d'impôt pour particulier de façon la plus optimale possible et cela dans des
                            délais raisonnables</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 mt-4">
                <div class="services-box">
                    <div class="d-flex">
                        <i class="pe-7s-display2 text-primary"></i>
                        <div class="ms-4">
                            <h4>Déclarations de revenus des sociétés (T2/CO17)</h4>
                            <p class="pt-2 text-muted">Pour un accompagnement personnalisé, nous vous aidons dans la déclaration d'impôt pour votre entreprise</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mt-4">
                <div class="services-box">
                    <div class="d-flex">
                        <i class="pe-7s-date text-primary"></i>
                        <div class="ms-4">
                            <h4>Services de paie</h4>
                            <p class="pt-2 text-muted">Selon vos besoins en matiere de Service de paie nous vous offrons de l'assistance et des solutions à prix compétitifs</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mt-4">
                <div class="services-box">
                    <div class="d-flex">
                        <i class="pe-7s-news-paper text-primary"></i>
                        <div class="ms-4">
                            <h4>Déclaration de taxes et de DAS</h4>
                            <p class="pt-2 text-muted">
                                Nous préparons vos déclarations de taxes et les déductions à la source pour les
                                gouvernements : provincial et fédéral.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 mt-4">
                <div class="services-box">
                    <div class="d-flex">
                        <i class="pe-7s-culture text-primary"></i>
                        <div class="ms-4">
                            <h4>Conciliation bancaire</h4>
                            <p class="pt-2 text-muted">Afin d'assurer l'exactitude de vos livres comptables, nous vous aidons à faire le rapprochement bancaire avec votre
                            comptabilité réel; afin de permettre un contrôle efficace des performances de votre entreprise et aussi de surveiller
                            toute action frauduleuse.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>