<section class="section bg-web-desc">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white">Nous vous proposons des solutions adaptées à vos besoins</h2>
                <p class="pt-3 home-desc mx-auto">Que vous soyez un particulier ou une entreprise nous vous guidons pour une gestion saine et complète de vos besoins en comptabilité et fiscalité</p>
            </div>
        </div>
    </div>
</section>