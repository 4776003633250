import { Component, AfterViewInit } from '@angular/core';
import { ScrollSpyService } from 'ngx-scrollspy';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements AfterViewInit {

  sections = {};
  curentsection: any;
  scrolled: boolean = false;

  constructor(private scrollSpyService: ScrollSpyService) { }

  ngOnInit() {
    const component = this;

    window.onscroll = function () {
      myFunction()
    };
    var navbar1 = document.getElementById("navbar1");
    function myFunction() {
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        navbar1.style.backgroundColor = "#ffffff";
        navbar1.style.padding = "0";
        component.scrolled = true;
      }
      else {

        navbar1.style.backgroundColor = "";
        navbar1.style.padding = "20px";
        component.scrolled = false;
      }
    }
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
    document.getElementById('menu_button').classList.toggle('collapsed');
  }

  ngAfterViewInit() {

    let sections: NodeListOf<Element> = document.querySelectorAll(".section");
    let self = this;

    Array.prototype.forEach.call(sections, function (e) {
      self.sections[e.id] = parseFloat(e.offsetTop);
    });

    this.scrollSpyService.getObservable('window').subscribe((e: any) => {
      let scrollPosition = document.documentElement.scrollTop;
      for (let menu in this.sections) {
        if (this.sections[menu] <= scrollPosition) {
          if (menu == "" || menu == "testi") { }
          else {
            this.curentsection = menu
          }
        }
      }
    });
  }

  clearMenu(color) {
    document.querySelector('a[data="id_home"]').setAttribute('style', 'color:' + color + '!important');
    document.querySelector('a[data="id_services"]').setAttribute('style', 'color:' + color + '!important');
    document.querySelector('a[data="id_about"]').setAttribute('style', 'color:' + color + '!important');
    document.querySelector('a[data="id_pricing"]').setAttribute('style', 'color:' + color + '!important');
    document.querySelector('a[data="id_about"]').setAttribute('style', 'color:' + color + '!important');
    document.querySelector('a[data="id_blog"]').setAttribute('style', 'color:' + color + '!important');
    document.querySelector('a[data="id_contact"]').setAttribute('style', 'color:' + color + '!important');
  }

  onSectionChange(section: any) {
    console.log('vhghgh', section)
  }
}
