<section class="section" id="about">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 order-2 order-lg-1">
                <div class="features-box mt-5 mt-lg-0 text-muted web-desc">
                    <h3>Qui sommes nous ?</h3>
                    <p><b>SCF La Référence</b> est un cabinet comptable de premier plan à Laval 
                       composé dans ses rangs, d'une équipe de professionnels dévoués. </p>
                    <h4>Comment nous distinguons nous ?</h4>                    
                    <p>
                    Nous combinons expérience comptable de pointe et innovation numérique pour offrir une expérience client
                    moderne et personnalisée. Notre approche intègre des outils digitaux avancés, facilitant la gestion comptable et fiscale
                    tout en offrant des conseils stratégiques personnalisés. Notre expérience s'étend au-delà des services traditionnels,
                    couvrant des domaines clés comme la planification fiscale et les services de conseils, garantissant ainsi une gestion
                    efficace et conforme à vos besoins uniques.</p>
                    <ul class="list-unstyled mt-4 features-item-list">
                    <h4>Nos points forts</h4>
                        <li class="">Nous sommes une équipe chevronnée à votre service.</li>
                        <li class="">Nous entretenons une relation de confiance avec nos clients.</li>
                        <li class="">Nous vous offrons un travail de qualité.</li>
                        <li class="">Nos services sont adaptés à tout type d'entreprise et aux besoins des particuliers.</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-2">
                <div class="features-img mx-auto me-lg-0">
                    <img src="assets/images/about-us-image.png" alt="macbook image" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>