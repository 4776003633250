
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar1" [ngClass]="{'page-scroll': scrolled}">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo text-uppercase" routerLink="/" style="display: flex; padding-top: 17px;padding-bottom: 10px;">
            <div style="width: 80%;" *ngIf="!scrolled">
                <img src="../../../assets/images/logo-white.png" alt="" style="width: 60px; margin-top: -11px;">
                <span class="home-title-1">
                    SCF la Référence
                </span>
            </div>
            <div *ngIf="scrolled">
                <img src="../../../assets/images/logo.png" alt="" style="width: 60px; margin-top: -11px;">
                <span style="color: #000;">
                    SCF la Référence
                </span>
            </div>
        </a>

        <button class="navbar-toggler" (click)="toggleMenu()" type="button" id="menu_button" data-toggle="collapse"
            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
            aria-label="Toggle navigation">
            <i class="mdi mdi-menu" [ngClass]="{'mdi-on-scroll': scrolled}"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse" scrollSpy (selectionchange)="onSectionChange($event)">
            <ul class="navbar-nav navbar-center" id="mySidenav">
                <li class="nav-item">
                    <a data="id_home" [ngClass]="{'active':curentsection === 'home'}" [ngxScrollTo]="'#home'" class="nav-link">Accueil</a>
                </li>
                <li class="nav-item">
                    <a data="id_about" [ngClass]="{'active':curentsection === 'about'}" [ngxScrollTo]="'#about'" class="nav-link">A
                        propos de nous</a>
                </li>
                <li class="nav-item">
                    <a data="id_services" [ngClass]="{'active':curentsection === 'services'}" [ngxScrollTo]="'#services'" class="nav-link">Services</a>
                </li>
                <li class="nav-item">
                    <a data="id_contact" [ngClass]="{'active':curentsection === 'contact'}" [ngxScrollTo]="'#contact'" class="nav-link">Contact</a>
                </li>
            </ul>
            <div class="nav-button ms-auto">
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <a href="mailto:lareferenceinc@gmail.com" class="email-title"><i class="mdi mdi-email-outline"></i></a>
                        <a href="tel:+14387258562"><i class="mdi mdi-phone"></i> +1 438 725 8562</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>