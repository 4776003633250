<div id="theme_id" class="">

    <app-header></app-header>

    <section class="section bg-home home-half" id="home" data-image-src="assets/images/bg-home.jpg">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center" style="padding: 38px 0;">
                <div class="col-lg-8 text-white text-center">
                    <h4 class="home-small-title">Services Comptables et Fiscaux la Référence</h4>
                    <h1 class="home-title">Votre partenaire pour un service professionnel et personnalisé</h1>
                    <p class="pt-3 home-desc mx-auto">
                        <button class="btn btn-primary mt-4 waves-effect waves-light" [ngxScrollTo]="'#contact'">Nous Contacter
                            <i class="mdi mdi-arrow-right"></i>
                        </button>
                    </p>
                </div>
            </div>
        </div>
        <div class="wave-effect wave-anim">
            <div class="waves-shape shape-one">
                <div class="wave wave-one" style="background-image: url('assets/images/wave-shape/wave1.png')"></div>
            </div>
            <div class="waves-shape shape-two">
                <div class="wave wave-two" style="background-image: url('assets/images/wave-shape/wave2.png')"></div>
            </div>
            <div class="waves-shape shape-three">
                <div class="wave wave-three" style="background-image: url('assets/images/wave-shape/wave3.png')"></div>
            </div>
        </div>
    </section>

    <app-about></app-about>
    <app-services></app-services>
    <app-description></app-description>
    <app-contact></app-contact>
    <app-footer></app-footer>
</div>
