<section class="section " id="contact">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <h1 class="section-title text-center">Contactez Nous</h1>
                <div class="section-title-border mt-3"></div>
                <p class="section-subtitle text-muted text-center font-secondary pt-4">Pour plus d'information sur nos services, pour prendre rendez-vous ou pour toutes autres préoccupations, n'hésitez pas à
                nous contacter. Nous aurons le plaisir de vous répondre.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="mt-4 pt-4">
                    <p class="mt-4">
                        <span class="h5">
                            <i class="mdi mdi-phone"></i>
                            Téléphone : 
                        </span><br>
                        <span class="text-muted d-block mt-2">
                            <a class="text-muted" href="tel:+14387258562">
                                +1 438 725 8562
                            </a>
                        </span>
                    </p>
                    <p class="mt-4">
                        <span class="h5">
                            <i class="mdi mdi-email-outline"></i>
                            Couriel :
                        </span><br>
                        <span class="text-muted d-block mt-2">
                            <a class="text-muted" href="mailto:lareferenceinc@gmail.com">
                                lareferenceinc@gmail.com
                            </a>
                        </span></p>
                    <p class="mt-4">
                        <span class="h5">
                            <i class="mdi mdi-google-maps"></i>
                            Adresse :
                        </span><br> 
                        <span class="text-muted d-block mt-2">
                            <a class="text-muted" href="https://maps.app.goo.gl/HjDMaKgaqrsBW5SN9" target="_blank">8040 Rue du Voltigeur, Laval QC H7A 4J4</a>
                        </span></p>
                    <p class="mt-4">
                        <span class="h5">
                            <i class="mdi mdi-google-circles-group"></i>
                            Heures d'ouverture:
                        </span><br> <span
                            class="text-muted d-block mt-2">
                            Lundi à Vendredi, de 8 h à 16 h 30</span></p>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="custom-form mt-4 pt-4">
                    <div id="message"></div>
                    <form method="post" action="#" name="contact-form" id="contact-form">
                        <p id="error-msg"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group mt-2">
                                    <input name="name" id="name" type="text" class="form-control"
                                        placeholder="Votre nom*">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mt-2">
                                    <input name="email" id="email" type="email" class="form-control"
                                        placeholder="Votre adresse courriel*">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mt-2">
                                    <input type="text" class="form-control" id="subject" placeholder="Service" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mt-2">
                                    <textarea name="comments" id="comments" rows="4" class="form-control"
                                        placeholder="Votre message..."></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-end">
                                <input type="button" id="submit" name="send" class="submitBnt btn btn-primary"
                                    value="Envoyer">
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>