<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <h4 style="display: flex;">
                    <div style="width: 60px;">
                        <img src="../../../assets/images/logo-white.png" alt="" style="width: 100%;">
                    </div>
                    <span style="display: block;margin: auto 9px;">
                        SCF La Référence
                    </span>
                </h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <li><a [ngxScrollTo]="'#home'">Accueil</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4">
                <h4>Liens utiles</h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <li><a [ngxScrollTo]="'#about'">A propos de nous</a></li>
                        <li><a [ngxScrollTo]="'#services'">Nos Service</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4">
                <h4>Information</h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <li><a [ngxScrollTo]="'#contact'">Nous Contactez</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="footer-alt">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="float-start pull-none">
                    <p class="copy-rights text-muted mb-3 mb-sm-0">{{ cur_year }} © EasyUp - Consulting</p>
                </div>
                <div class="float-end pull-none ">
                    <ul class="list-inline d-flex flex-wrap social m-0">
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-facebook"></i></a></li>
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-twitter"></i></a></li>
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i
                                    class="mdi mdi-linkedin"></i></a></li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
