import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScrollSpyModule, ScrollSpyService } from 'ngx-scrollspy';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';
import { ContactComponent } from './pages/contact/contact.component';
import { DescriptionComponent } from './pages/description/description.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ScrollspyDirective,
    ServicesComponent, DescriptionComponent,
    ContactComponent, AboutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    ScrollSpyModule.forRoot(),
    ScrollToModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [ScrollSpyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
