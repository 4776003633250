import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.css']
})

// description component
export class DescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
